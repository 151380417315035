// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   appVersion: '1.0.0',
//   origin: 'KnowledgeBase Mobile',
//   production: false,
//   enableDebug: true,
//   defaultLang: 'de',
//   api: {
//     baseUrl: 'http://localhost:9099',
//     endpoint: '/api'
//   },
//   dbName: 'knowledge_db'
// };

export const environment = {
  appVersion: '1.0.0',
  origin: 'KnowledgeBase Mobile',
  production: true,
  enableDebug: false,
  defaultLang: 'de',
  api: {
    baseUrl: 'https://knowledge.time2tri.me',
    endpoint: '/api'
  },
  dbName: 'knowledge_db'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

