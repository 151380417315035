import {Component, OnDestroy} from '@angular/core';

import {Platform} from '@ionic/angular';
import {environment} from '../environments/environment';
import * as moment from 'moment';
// import {ThemeService} from '@time2tri/ion-common';
import {Plugins, PushNotification,} from '@capacitor/core';
import {StatusBarStyle} from '@capacitor/core/dist/esm/core-plugin-definitions';
import {TranslateService} from '@ngx-translate/core';
const {SplashScreen, Network, StatusBar} = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy
{

    public selectedTheme: string;

    private networkHandler: any;

    constructor(
        private platform: Platform,
        private translateService: TranslateService,
        // private themeService: ThemeService,
    )
    {
        this.initializeApp();
    }

    ngOnDestroy(): void
    {
        // unsubscribe network changes
        this.networkHandler.remove();
    }

    initializeApp()
    {
        this.platform.ready().then(() =>
        {
            this.setupTranslation();
            this.listenToNetworkChanges();

            if(this.platform.is('cordova'))
            {
                SplashScreen.hide();
                StatusBar.setStyle({style: StatusBarStyle.Dark});
            }

            //   this.themeService.getActiveTheme().subscribe(
            //     (theme: string) => {
            //         this.selectedTheme = theme;
            //         // @ts-ignore
            //         this.themeService.updateStatusBar(theme, this.platform);
            //     }
            // );
        });

        // Use matchMedia to check the user preference
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

        toggleDarkTheme(prefersDark.matches);
        // toggleLightTheme(prefersDark.matches);

        // Listen for changes to the prefers-color-scheme media query
        prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

        // Add or remove the "dark" class based on if the media query matches
        function toggleDarkTheme(shouldAdd)
        {
            document.body.classList.toggle('dark', true);
        }

        function toggleLightTheme(shouldAdd)
        {
            document.body.classList.toggle('dark', false);
        }
    }

    private async setupTranslation()
    {
        // const appLanguageCode = await this.deviceService.getLanguageCode();

        // this.translateService = this.userService.setupLocale(this.translateService, this.currentUser);

        // const lang = localStorage.getItem('language') ? localStorage.getItem('language') : appLanguageCode;
        const lang = localStorage.getItem('language') ? localStorage.getItem('language') : environment.defaultLang;
        this.translateService.setDefaultLang(lang);

        // will be updated if user data is available. See UserService setLocaleAndTimeZone()
        // moment.tz.setDefault(this.ENV.DEFAULT_TIMEZONE);
        moment.locale(lang);
    }

    private async listenToNetworkChanges()
    {
        this.networkHandler = Network.addListener('networkStatusChange', (status) => {
            console.log('[AppComponent] Network status changed', status);

            if(status && status.connected === false)
            {
                // TODO Show Overlay
                alert('offline');
            }
        });

        // Get the current network status
        // let status = await Network.getStatus();
    }

    private async initPushNotifications()
    {
        // TODO
        // await this.pushProvider.init();
        //
        // this.pushProvider.pushNotificationReceived.subscribe(
        //     (notification: PushNotification) => {
        //         console.log('[AppComponent] PN received notification', notification);
        //         alert('Push received: ' + JSON.stringify(notification));
        //     }
        // );
    }

}


