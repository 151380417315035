import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/tabs/tabs.module').then(m => m.TabsModule)
  },
  {
    path: 'category-detail',
    loadChildren: () => import('./features/categories/pages/category-detail/category-detail.module').then( m => m.CategoryDetailPageModule)
  },
  {
    path: 'article-detail/:id',
    loadChildren: () => import('./features/articles/pages/article-detail/article-detail.module').then( m => m.ArticleDetailPageModule)
  },
  // {
  //   path: 'writer-detail',
  //   loadChildren: () => import('./writers/writer-detail/writer-detail.module').then( m => m.WriterDetailPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
