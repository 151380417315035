import {NgModule, enableProdMode, LOCALE_ID, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpHeaders} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {IonicStorageModule} from '@ionic/storage';
import {MomentModule} from 'ngx-moment';
import { IonicHeaderParallaxModule } from 'ionic-header-parallax';
import { LazyLoadImageModule } from 'ng-lazyload-image';

/**
 * Enable Angular Prod Mode if we are in Production Context
 */
if (environment.production === true)
{
    console.log('[AppModule] Production Mode enabled');
    enableProdMode();
}

/**
 * The translate loader needs to know where to load i18n files
 * in Ionic's static asset pipeline.
 */
export function createTranslateLoader(http: HttpClient)
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicModule.forRoot(
            {
                rippleEffect: true,
                loadingSpinner: 'crescent',
                refreshingSpinner: 'crescent',
                backButtonText: '',
                mode: 'ios',
                backButtonIcon: 'md-arrow-back'
            }
        ),
        IonicStorageModule.forRoot(
            {
                name: environment.dbName
            }
        ),
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [HttpClient]
                }
            }
        ),
        HttpClientModule,
        IonicHeaderParallaxModule,
        LazyLoadImageModule,
        MomentModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: LOCALE_ID,
            useValue: window.navigator.language
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
